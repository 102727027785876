<template>
  <div>
    <h3>{{ $t("searchView.new_coll_title") }}</h3>
    <v-text-field
      label="Title of new collection"
      v-model="newCollection.title"
      outline
      clearable
      data-test="collection-creation-title"
    />
    <CollectionCategorySelection
      data-test="collection-creation-category-selection"
      :selectionLabel="'Collections.categories-selection-label'"
      v-model="selectedCollectionCategory"
    />
    <v-checkbox
      data-test="collection-creation-jump-to-collection-toggle"
      label="jump to collection after creating"
      v-model="jumpToNewCollection"
    />
    <v-btn
      data-test="collection-creation-create-button"
      primary
      :disabled="buttonDisabled"
      :loading="isLoading"
      @click="createNewCollection()"
    >
      Create
    </v-btn>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { createDocumentsForCollection } from "@/api/django/documents";
import Logger from "../services/LoggerService";
import { CollectionsModule } from "../store/modules/collections";
import CollectionCategorySelection from "./CollectionCategorySelection.vue";
import { ApiCollectionParameters } from "../static/apiModels";
import {
  CollectionCategory,
  noCollectionCategory,
  MorphemToCategory,
  possibleCategoryMorphems,
} from "../static/collectionConstants";
import { NotificationsModule } from "../store/modules/notifications";
import { esDocument } from "../api/elastic/elasticTypes";

@Component({
  components: {
    CollectionCategorySelection,
  },
  name: "CollectionCreation",
})
export default class CollectionCreation extends Vue {
  @Prop({ required: true }) selection!: string[] | esDocument[];

  @Prop({ required: false }) curators?: string[];

  @Prop() loading?: boolean;

  l = new Logger("DocumentCreation.vue");

  CM = CollectionsModule;

  selectedCollectionCategory: CollectionCategory = noCollectionCategory;

  jumpToNewCollection: boolean = true;

  localLoading: boolean = false;

  get isLoading() {
    return this.localLoading || this.CM.loading || this.loading;
  }

  get title() {
    return this.newCollection.title;
  }

  @Watch("title")
  onTitleChange() {
    const newCategory = this.getCategoryFromTitel(this.newCollection.title);
    if (newCategory !== noCollectionCategory) {
      this.selectedCollectionCategory = newCategory;
    }
  }

  newCollection: ApiCollectionParameters = {
    title: "",
    description: "",
    es_document: [],
    category: null,
    curator: this.curators,
  };

  get buttonDisabled() {
    return this.selection.length === 0 || !this.newCollection.title.trim() || this.isLoading;
  }

  async createNewCollection() {
    this.setLoading();

    if (typeof this.selection[0] === "string") {
      this.newCollection.es_document = this.selection as string[];
    } else if (this.isEsDocument(this.selection[0])) {
      const docs = await this.getDocUrlsFromSelection();

      if (!docs) {
        this.setLoading(false);
        return;
      }
      this.newCollection.es_document = docs;
    } else {
      NotificationsModule.setError({
        message: "Selection is broken. Please contact dev guys!",
        icon: "mdi-smile",
      });
      this.l.error("Selection is neither string[] nor esDocument[]");
      this.setLoading(false);
      return;
    }

    this.newCollection.category = this.selectedCollectionCategory.id;

    await this.CM.createCollection({
      newCollection: this.newCollection,
      jumpToNewCollection: this.jumpToNewCollection,
    });
    this.setLoading(false);
  }

  setLoading(value: boolean = true) {
    this.localLoading = value;
  }

  async getDocUrlsFromSelection(): Promise<string[] | null> {
    const esDocs = this.selection as esDocument[];
    const docs = esDocs.map((document: esDocument) => {
      // eslint-disable-next-line no-underscore-dangle
      return { es_id: document._id };
    });

    let docsInsteredSuccessfully = true;
    const response = await createDocumentsForCollection(docs).catch(() => {
      docsInsteredSuccessfully = false;
      NotificationsModule.setError({
        message: "Error on inserting Documents. Please contact dev guys!",
        icon: "mdi-smile",
      });
    });

    if (!docsInsteredSuccessfully) {
      return null;
    }

    const documentsUrls: string[] = [];
    // @ts-ignore
    response.data.forEach((doc: any) => {
      documentsUrls.push(doc.url);
    });
    return documentsUrls;
  }

  isEsDocument(object: any): object is esDocument {
    return "_id" in object && "_source" in object;
  }

  getCategoryFromTitel(title: string): CollectionCategory {
    const words = title.split("_");

    for (let i = 0; i < possibleCategoryMorphems.length; i += 1) {
      const morphem = possibleCategoryMorphems[i];
      if (words.includes(morphem)) {
        return MorphemToCategory(morphem);
      }
    }

    return noCollectionCategory;
  }
}
</script>
