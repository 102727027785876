<template>
  <v-select
    data-test="select-collection-category"
    v-model="selectedCollectionCategory"
    :items="collectionCategories"
    item-value="id"
    :label="$t(selectionLabel)"
    return-object
    class="pt-3"
    menu-props="auto"
  >
    <template v-slot:selection="data">
      <span data-test="select-selected-collection-category">
        {{ $t(data.item.categoryNameLocalizationKey) }}
      </span>
    </template>
    <template v-slot:item="data">
      <span v-if="data.item.id === ''" class="d-sr-only">
        Empty Option
      </span>
      <span :data-test="`select-collection-category-option-${data.item.id}`">
        {{ $t(data.item.categoryNameLocalizationKey) }}
      </span>
    </template>
  </v-select>
</template>

<script lang="ts">
import { Component, Vue, Prop, VModel } from "vue-property-decorator";
import { CollectionCategory, possibleCollectionCategories } from "../static/collectionConstants";

@Component({
  name: "CollectionCategorySelect",
})
export default class CollectionCategorySelect extends Vue {
  @VModel() selectedCollectionCategory: any;

  @Prop() selectionLabel: any;

  collectionCategories: CollectionCategory[] = possibleCollectionCategories;
}
</script>
