<!-- shows a single tag given an url-->
<template>
  <v-chip
    :data-test="`${componentName}-chip`"
    v-if="!loading"
    :class="' overflow-hidden ' +  (shootable ? 'xcursor' : '')"
    style="max-width: 100%;"
    :input-value="selected ? true : false"
    small
    :color="tag.color"
    @click="$emit('tagClicked', $event)"
    :title="tag.name"
  >
    <v-avatar>
      {{ tag.emoji }}
    </v-avatar>
    <span :class="{ darktag: brightness <= 120, brighttag: brightness >= 130 }" >
      {{ tag.name }}
    </span>
  </v-chip>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Logger from "../services/LoggerService";
import { TagsModule } from "../store/modules/tags";

@Component({
  components: {},
  name: "TagChip",
})
export default class TagChip extends Vue {
  componentName = "TagChip";

  @Prop(String) readonly url: string | undefined;

  @Prop(String) readonly selected: boolean | undefined;

  @Prop(Boolean) readonly shootable: boolean | undefined;

  l = new Logger("TagChip.vue");

  TM = TagsModule;

  get loading() {
    return this.tag === null;
  }

  get tag() {
    if (this.url) return TagsModule.getTag(this.url);
    return null;
  }

  get brightness() {
    if (!this.tag) return 127;
    const c = this.hexToRgb(this.tag.color);
    if (!c) return 127;
    return (c.r + c.r + c.g + c.g + c.g + c.b) / 6; // Taking into account the human eyes preference G > R > B
  }

  // Credits
  hexToRgb(hex: string) {
    if (hex.indexOf("#") !== 0) return null;
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
}
</script>

<style scoped lang="scss">
.darktag {
  color: white;
}

.brighttag {
  color: black;
}

.xcursor {
  cursor: crosshair;
}

.xcursor span {
  cursor: crosshair;
}

.xcursor > span {
  cursor: crosshair;
}

.xcursor + span {
  cursor: crosshair;
}
</style>
