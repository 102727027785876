<template>
  <div>
    <TaggingToggleBtn
      data-test="tagging-nav-toggle-btn"
      :toggledOffset="bottomBarHeight"
    />
    <v-bottom-navigation
      :value="SM.getTaggingMode"
      v-model="bottomNavValue"
      v-show="isActive"
      :input-value="isActive"
      scroll-threshold="1"
      elevation-10
      :min-height="bottomBarHeight"
      app
      class="px-4"
    >
      <v-btn
        data-test="tagging-nav-switch-selection-mode-btn"
        @click="TM.switchTagSelectionMode"
        text
      >
        {{ TM.getTagSelectionMode }}
      </v-btn>

      <v-btn
        data-test="tagging-nav-add-slot-btn"
        @click="addSlot()"
        color="primary"
        text
        class="pa-4"
      >
        <span>{{ $t("TaggingNav.add_slot") }}</span>
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-container class="px-4">
        <v-btn
          :data-test="`tagging-nav-tag-slot-${tSlot.key}`"
          v-for="tSlot of storedSlots"
          :key="tSlot.key"
          color="primary"
          @click="!tSlot.tag ? TM.setDialog(true) : handleTagSlotClick($event, tSlot)"
          text
          @contextmenu.prevent.stop="handleClick($event, tSlot)"
          class="px-1 mb-4 pb-2 overflow-hidden btn-max-width"
          elevation="2"
          width="12.5%"
        >
          <template>
            <TagChip
              v-if="tSlot.tag"
              :url="tSlot.tag.url"
            >
            </TagChip>
            <span v-else>{{ $t("basics.no_selection") }}</span>
          </template>
          <span class="big">{{ tSlot.key.toUpperCase() }}</span>
        </v-btn>
      </v-container>

      <v-btn
        data-test="tagging-nav-assign-tags-btn"
        @click="TM.setDialog(true)"
        color="primary"
        text
        class="pa-3"
      >
        <span>{{ $t("TaggingNav.Tags zuweisen") }}</span>
        <v-icon>mdi-tag-multiple</v-icon>
      </v-btn>

      <v-btn
        data-test="tagging-nav-create-tags-btn"
        @click="TM.setCreationDialog(true)"
        color="primary"
        text
        class="pa-3"
      >
        <span>{{ $t("TaggingNav.new_Tag") }}</span>
        <v-icon>mdi-tag-plus</v-icon>
      </v-btn>

      <v-btn
        data-test="tagging-nav-edit-tags-btn"
        @click="TM.setEditDialog(true)"
        color="primary"
        text
        class="pa-3"
      >
        <span>{{ $t("TaggingNav.edit_Tag") }}</span>
        <v-icon>mdi-tag-text-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <VueSimpleContextMenu
      :elementId="'ContextMenuTagNavCollection'"
      :options="cmTagSlotWithTagOptionsArray"
      :ref="'vueSimpleContextMenuFull'"
      @option-clicked="optionClicked"
    ></VueSimpleContextMenu>

    <VueSimpleContextMenu
      :elementId="'ContextMenuTagNavSlotEmpty'"
      :options="cmTagSlotEmptyOptionsArray"
      :ref="'vueSimpleContextMenuEmpty'"
      @option-clicked="optionClicked"
    ></VueSimpleContextMenu>

    <VueSimpleContextMenu
      :elementId="'ContextMenuTagNavNoSelection'"
      :options="cmTagSlotNoSelectionOptionsArray"
      :ref="'vueSimpleContextMenuNoSelection'"
      @option-clicked="optionClicked"
    ></VueSimpleContextMenu>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Logger from "../services/LoggerService";
import { SettingsModule } from "../store/modules/settings";
import { TagsModule } from "../store/modules/tags";
import TagSelect from "./TagSelect.vue";
import TagChip from "./TagChip.vue";
import { ApiTag } from "../static/apiModels";
import { NotificationsModule } from "../store/modules/notifications";
import CreateTags from "./createTags.vue";
import VueSimpleContextMenu from "./simple-context-menu.vue";
import { CollectionsModule } from "../store/modules/collections";
import TaggingToggleBtn from "./TaggingToggleBtn.vue";

@Component({
  components: {
    TagSelect,
    TagChip,
    CreateTags,
    VueSimpleContextMenu,
    TaggingToggleBtn,
  },
  name: "TaggingNav",
})
export default class TaggingNav extends Vue {
  l = new Logger("TaggingNav.vue");

  SM = SettingsModule;

  TM = TagsModule;

  bottomBarHeight = 70;

  cmTagSlotWithTagOptionsArray = [
    {
      name: this.$t("TaggingNav.add_to_selection"),
      slug: "addToSelection",
    },
    {
      name: this.$t("TaggingNav.remove_from_selection"),
      slug: "removeFromSelection",
    },
    {
      name: this.$t("TaggingNav.assign_tag_to_slot"),
      slug: "assignTagToSlot",
    },
    {
      name: this.$t("TaggingNav.remove_tag_from_slot"),
      slug: "removeTagFromSlot",
    },
  ];

  cmTagSlotNoSelectionOptionsArray = [
    {
      name: this.$t("TaggingNav.assign_tag_to_slot"),
      slug: "assignTagToSlot",
    },
    {
      name: this.$t("TaggingNav.remove_tag_from_slot"),
      slug: "removeTagFromSlot",
    },
  ];

  cmTagSlotEmptyOptionsArray = [
    {
      name: this.$t("TaggingNav.assign_tag_to_slot"),
      slug: "assignTagToSlot",
    },
  ];

  bottomNavValue: number | null = null;

  get isActive(): boolean {
    return this.SM.getTaggingMode;
  }

  get selectedTag() {
    return this.TM.getSelectedTag;
  }

  get storedSlots() {
    return this.TM.getSlots;
  }

  adaptiveBotomBarHeight() {
    const baseHeight = 70;
    const height = Math.ceil(this.storedSlots.length / 9) * baseHeight;
    this.bottomBarHeight = height >= baseHeight ? height : baseHeight;
  }

  handleTagSlotClick(event: any, slot: any) {
    const localSelectedTag = slot.tag;
    // this.l.debug('slot selected', slot);
    if (localSelectedTag) {
      this.TM.setSelectedTag(localSelectedTag);
      if (localSelectedTag) {
        NotificationsModule.setSuccess({
          message: `successfully loaded tag '${localSelectedTag.name}', shoot all lines!`,
          icon: "mdi-crosshairs-gps",
        });
      }
    }
  }

  handleClick(event: any, item: any) {
    if (item.tag !== null) {
      if (CollectionsModule.selection.length > 0) {
        // @ts-ignore
        this.$refs.vueSimpleContextMenuFull.showMenu(event, item);
      } else {
        // @ts-ignore
        this.$refs.vueSimpleContextMenuNoSelection.showMenu(event, item);
      }
    } else {
      // @ts-ignore
      this.$refs.vueSimpleContextMenuEmpty.showMenu(event, item);
      this.l.info("No Tag in this slot.");
    }
  }

  optionClicked(event: any) {
    const localSelectedTag = event.item.tag;

    switch (event.option.slug) {
      case "addToSelection":
        if (localSelectedTag !== null) {
          this.addToSelection(localSelectedTag);
        } else {
          this.l.info("No Tag in this slot.");
        }
        break;
      case "removeFromSelection":
        if (localSelectedTag !== null) {
          this.removeFromSelection(localSelectedTag);
        } else {
          this.l.info("No Tag in this slot.");
        }
        break;
      case "assignTagToSlot":
        this.TM.setSlotToEdit(event.item);
        this.TM.setSingleTagSlotSelectionDialog(true);
        break;
      case "removeTagFromSlot":
        this.TM.setSlotToEdit(event.item);
        this.TM.removeTagFromSlotToEdit();
        break;
      default:
        this.l.error("@dev did not implement this feature yet!!");
    }
  }

  addSlot() {
    this.TM.addNewSlot();
    this.adaptiveBotomBarHeight();
  }

  addToSelection(tag: ApiTag) {
    CollectionsModule.addTagToSelection({ tag });
  }

  removeFromSelection(tag: ApiTag) {
    CollectionsModule.removeTagFromSelection({ tag });
  }

  mounted() {
    this.adaptiveBotomBarHeight();
  }
}
</script>

<style scoped lang="scss">
.big {
  font-size: 20px;
  font-weight: 600;
  //background-color: red;
}

.btn-max-width::v-deep .v-btn__content {
  max-width: 100%;
}
</style>
