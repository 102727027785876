<template>
  <SearchView></SearchView>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import SnackbarSwitch from "./SnackbarSwitch.vue";
import DarkmodeSwitch from "./darkmodeSwitch.vue";
import Logger from "../services/LoggerService";
import SearchView from "./searchView.vue";

import { SettingsModule } from "../store/modules/settings";

@Component({
  // if you use components add them here
  components: { SnackbarSwitch, DarkmodeSwitch, SearchView },
  /* name is necessary for recursive components
   * (at least in older versions, might be auto generated through the vue-property-decorator)
   */
  name: "Create",
})
export default class CreationView extends Vue {
  l = new Logger("CreationView.vue");

  // lifecycle hook
  mounted() {
    SettingsModule.leaveTaggingMode();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
