import Vue from "vue";
import Router from "vue-router";
import Login from "./components/Login.vue";
import CollectionEditView from "./components/CollectionEditView.vue";
import CollectionSingleView from "./components/CollectionSingleView.vue";
import Collections from "./components/Collections.vue";
import Settings from "./components/Settings.vue";
import CreationView from "./components/CreationView.vue";
import Main from "./components/Main.vue";
import LogView from "./views/LogView.vue";
import DocHeaderFilter from "./components/DocHeaderFilter.vue";
import ScanView from "./views/ScanView.vue";
import LemmaView from "./components/LemmaView.vue";
import LemmaSingleView from "./components/LemmaSingleView.vue";
import LemmaEditView from "./components/LemmaEditView.vue";
import TaskView from "./components/TaskView.vue";
import TaskCreate from "./components/TaskCreate.vue";
import TaskSingleView from "./components/TaskSingleView.vue";
import TaskEdit from "./components/TaskEdit.vue";
import ReportView from "./components/ReportView.vue";

Vue.use(Router);

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      name: "login",
      path: "/login",
      component: Login,
    },
    {
      name: "home",
      path: "",
      component: Main,
      children: [
        {
          name: "create",
          path: "create",
          component: CreationView,
          meta: {
            requireLogin: true,
          },
        },
        {
          name: "createSingle",
          path: "create/:id",
          component: CreationView,
          meta: {
            requireLogin: true,
          },
        },
        {
          name: "settings",
          path: "settings",
          component: Settings,
          meta: {
            requireLogin: true,
          },
        },
        {
          name: "log",
          path: "log",
          component: LogView,
          meta: {
            requireLogin: false,
          },
        },
        {
          name: "Collections",
          path: "home",
          component: Collections,
          meta: {
            requireLogin: true,
          },
        },
        {
          name: "collection Single View",
          path: "collections/:id",
          component: CollectionSingleView,
          meta: {
            requireLogin: false,
          },
        },
        {
          name: "collection Edit View",
          path: "collections/:id/edit",
          component: CollectionEditView,
          meta: {
            requireLogin: false,
          },
        },
        {
          name: "lemma",
          path: "lemma",
          component: LemmaView,
          meta: {
            requireLogin: false,
          },
        },
        {
          name: "Lemma Single View",
          path: "lemma/:id",
          component: LemmaSingleView,
          meta: {
            requireLogin: false,
          },
        },
        {
          name: "Task Create View",
          path: "task/create",
          component: TaskCreate,
          meta: {
            requireLogin: false,
          },
        },
        {
          name: "Lemma Edit View",
          path: "lemma/:id/edit",
          component: LemmaEditView,
          meta: {
            requireLogin: false,
          },
        },
        {
          name: "Task Single View",
          path: "task/:id",
          component: TaskSingleView,
          meta: {
            requireLogin: false,
          },
        },
        {
          name: "Task View",
          path: "task",
          component: TaskView,
          meta: {
            requireLogin: false,
          },
        },
        {
          name: "Report View",
          path: "report",
          component: ReportView,
          meta: {
            requireLogin: false,
          },
        },
        {
          name: "Task Edit View",
          path: "task/:id/edit",
          component: TaskEdit,
          meta: {
            requireLogin: true,
          },
        },
        {
          name: "test",
          path: "test",
          component: DocHeaderFilter,
          meta: {
            requireLogin: false,
          },
        },
        {
          name: "scans",
          path: "scans",
          component: ScanView,
          meta: {
            requireLogin: true,
          },
        },
        {
          name: "paperslips",
          path: "paperslips",
          component: ScanView,
          meta: {
            requireLogin: true,
          },
        },
        {
          name: "collections",
          path: "*",
          component: Collections,
          meta: {
            requireLogin: false,
          },
        },
      ],
    },
  ],
});
