<template>
  <vContainer>
    <v-form>
      <v-layout
        row
        justify-space-around
      >
        <v-flex md2>
          <v-text-field
            data-test="lemma-filter-norm-filter"
            @change="writeStoreFilter"
            label="norm"
            outline
            v-model="filters.norm"
            clearable
          ></v-text-field>
        </v-flex>
        <v-flex md2>
          <v-text-field
            data-test="lemma-filter-count-filter"
            v-model="filters.count__gt"
            hide-details
            single-line
            label="count"
            type="number"
            @change="writeStoreFilter"
          />
        </v-flex>
        <v-flex md2>
          <v-layout
            justify-space-around
            column
          >
            <!--
            <v-flex md1>
              <v-checkbox
                v-model="noCollection"
                @change="changeCollection($event)"
                label="Keine zugewiesene Collection"
              ></v-checkbox>
            </v-flex>-->
            <v-flex md1>
              <v-checkbox
                data-test="lemma-filter-no-norm-checkbox"
                v-model="noNorm"
                @change="changeNorm($event)"
                label="Keine Normalisierung"
              ></v-checkbox>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex md2>
          <v-layout
            justify-space-around
            column
          >
            <v-flex md1>
              <v-checkbox
                data-test="lemma-filter-no-assignee-checkbox"
                v-model="noAssignee"
                @change="changeAssignee($event)"
                label="Keine zugewiesenen User"
              ></v-checkbox>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex md2>
          <v-layout
            justify-space-around
            column
          >
            <v-flex md1>
              <v-checkbox
                data-test="lemma-filter-no-collection-checkbox"
                v-model="noCollection"
                @change="changeCollection($event)"
                label="Keine zugewiesene Collection"
              ></v-checkbox>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout
        row
        justify-space-around
      >
        <v-row no-gutters>
          <v-col
            cols="12"
            sm="3"
            offset-sm="1"
          >
            <h2>User auswählen</h2>
            <ApiSelect
              data-test="lemma-filter-assigned-user-select"
              itemText="username"
              label="user"
              type="users"
              @input="l.debug('as', $event)"
              v-on:keyup.enter="onAssignedUser()"
              @click:append-outer="onAssignedUser()"
              :multiple="false"
              v-model="assignedUser"
              :filterValue="assignedUser"
            ></ApiSelect>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            offset-sm="1"
          >
            <h2>Aufgabenfilter</h2>
            <v-select
              data-test="lemma-filter-task-select"
              v-model="taskNum"
              :items="taskItems"
              item-text="name"
              item-value="val"
              label="Aufgabenfilter wählen"
            ></v-select>
          </v-col>
        </v-row>
      </v-layout>
    </v-form>
  </vContainer>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";

import { UsersModule } from "@/store/modules/users";
import { ApiLemmaRequest, ApiUser } from "../static/apiModels";
import ApiSelect from "./ApiSelect.vue";
import { LemmaModule } from "../store/modules/lemma";
import Logger from "../services/LoggerService";

@Component({
  // if you use components add them here
  components: { ApiSelect },
  /* name is necessary for recursive components
   * (at least in older versions, might be auto generated through the vue-property-decorator)
   */
  name: "LemmaFilter",
})
export default class LemmaFilter extends Vue {
  l = new Logger("LemmaFilter.vue");

  myLemmata = false;

  noAssignee = false;

  UM = UsersModule;

  noCollection: boolean = false;

  taskStatus: boolean = false;

  assignedUser: ApiUser | null = null;

  noNorm = false;

  countNum = 0;

  specficUser = "";

  taskNum: number = -1;

  taskItems = [
    { name: "Kein Filter ausgewählt", val: -1 },
    { name: "Keiner Aufgabe zugewiesen", val: 0 },
    { name: "Bereits Aufgabe zugewiesen", val: 1 },
    { name: "Kein Bearbeiter zugewiesen", val: 2 },
  ];

  get filters(): ApiLemmaRequest {
    return LemmaModule.filters;
  }

  @Watch("assignedUser")
  onAssignedUser() {
    if (this.assignedUser !== null && this.assignedUser !== undefined) {
      const regexp = /\/(\d+)\/$/g;
      const id = regexp.exec(this.assignedUser.url);
      this.filters.users = id ? id[1] : "";
    } else {
      delete this.filters.users;
    }
    this.writeStoreFilter();
  }

  @Watch("taskNum")
  onTaskStatusChange() {
    if (this.taskNum === -1) {
      delete this.filters.task;
    } else {
      if (this.noAssignee) {
        this.noAssignee = false;
      }
      this.filters.task = this.taskNum;
    }
    this.writeStoreFilter();
  }

  @Watch("taskNum")
  onTaskNumChange() {
    // this.writeStoreFilter();
    this.changeTask(this.taskStatus);
  }

  writeStoreFilter() {
    this.l.log("Write lemma filters");
    LemmaModule.setFilters({ ...this.filters });
  }

  changeNorm(hasNorm: boolean) {
    if (hasNorm) {
      this.filters.has__norm = 2;
    } else {
      delete this.filters.has__norm;
    }
    this.writeStoreFilter();
  }

  changeTask(hasTask: boolean) {
    if (hasTask) {
      this.filters.task = this.taskNum;
    } else {
      delete this.filters.task;
    }
    if (hasTask) {
      this.writeStoreFilter();
    }
  }

  changeAssignee(hasAssignee: boolean) {
    if (hasAssignee) {
      this.filters.task = 2;
    } else {
      delete this.filters.task;
    }
    this.writeStoreFilter();
  }

  changeCollection(hasCollection: boolean) {
    if (hasCollection) {
      this.filters.collection = 1;
    } else {
      delete this.filters.collection;
    }
    this.writeStoreFilter();
  }
}
</script>
