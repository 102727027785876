import { Module, VuexModule, MutationAction, Mutation, getModule } from "vuex-module-decorators";

import store from "@/store";
import { getSearchedDocument, getSearchedDocumentFullTextSearch } from "@/api/elastic/search";
import { DocumentsModule } from "@/store/modules/documents";
import Logger from "@/services/LoggerService";
import { getDocuments } from "@/api/elastic/documents";

export interface ISearchState {
  response: any[];
  loading: boolean;
}

const l = new Logger("SearchModule");

@Module({
  dynamic: true,
  store,
  name: "search",
  namespaced: true,
})
class search extends VuexModule implements ISearchState {
  response: any[] = [];

  loading: boolean = false;

  get getSearch(): any[] {
    // @ts-ignore
    if (this.response.hits) {
      // @ts-ignore
      return this.response.hits.hits;
    }
    return [];
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @MutationAction({ mutate: ["response", "loading"] })
  async fetchSearchedDocuments(fullsearch?: string) {
    // TODO: atm the fetched searches overwrite each other based on the order they are resovled in. Instead it should only use the last one.
    // Idea: use counter to check if the request is still valid.
    const filters = DocumentsModule.getFilters;
    l.log("this in fetchSearch", this);
    // @ts-ignore
    this.commit("setLoading", true);
    this.loading = true;
    let PARAMS: any;
    let responseTemp;
    if (filters.exist === false) {
      const searchVal = fullsearch ? fullsearch.replace(/\(|\)/gm, "") : fullsearch;
      responseTemp = await getSearchedDocumentFullTextSearch(searchVal);
    } else {
      PARAMS = {
        query: {
          bool: {
            must: [],
          },
        },
      };
      Object.keys(filters).forEach((item) => {
        const temp = {};
        // @ts-ignore
        temp[item] = filters[item];
        if (item === "KT") {
          PARAMS.query.bool.must.push({
            bool: {
              should: [
                // @ts-ignore
                { match: { KT1: temp.KT } },
                // @ts-ignore
                { match: { KT2: temp.KT } },
                // @ts-ignore
                { match: { KT3: temp.KT } },
                // @ts-ignore
                { match: { KT4: temp.KT } },
                // @ts-ignore
                { match: { KT5: temp.KT } },
                // @ts-ignore
                { match: { KT6: temp.KT } },
                // @ts-ignore
                { match: { KT7: temp.KT } },
                // @ts-ignore
                { match: { KT8: temp.KT } },
              ],
            },
          });
        } else if (item === "BD") {
          PARAMS.query.bool.must.push({
            bool: {
              should: [
                // @ts-ignore
                { match: { "BD/LT*": temp.BD } },
                // @ts-ignore
                { match: { "BD/KT*": temp.BD } },
                // @ts-ignore
                { match: { WBD: temp.BD } },
                // @ts-ignore
                { match: { "BD/LW*": temp.BD } },
              ],
            },
          });
        } else if (item !== "exist") {
          let tempString: String = JSON.stringify(temp);
          tempString = tempString.slice(1, -1);
          while (tempString.indexOf('"') !== -1) {
            tempString = tempString.replace('"', "");
          }
          if (!tempString.includes("null")) {
            // deleted values were still sent as a string query null:col -> including in the query only the relevant items
            PARAMS.query.bool.must.push({
              query_string: { query: tempString, minimum_should_match: "100%" },
            });
          }
        }
      });
      responseTemp = await getSearchedDocument(PARAMS);
    }
    l.log("responseTemp", responseTemp);
    return { response: responseTemp.data, loading: false };
  }

  @MutationAction({ mutate: ["response", "loading"] })
  async fetchSearchedDocumentsId() {
    const filter = DocumentsModule.getFilters;
    // @ts-ignore
    this.commit("setLoading", true);
    this.loading = true;

    // @ts-ignore
    const filtersArray = filter.ID.split(",");
    const response = await getDocuments(filtersArray);

    return { response: response.data, loading: false };
  }
}

export const SearchModule = getModule(search);
